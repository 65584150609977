import React, { useState, useEffect } from "react";
import HomeNavbar from "./components/CommonHomePage/HomeNavbar/HomeNavbar.jsx";
import HomeHero from "./components/CommonHomePage/HomeHero/HomeHero.jsx";
import HomeAbout from "./components/CommonHomePage/HomeAbout/HomeAbout.jsx";
import HomeTools from "./components/CommonHomePage/HomeTools/HomeTools.jsx";
import HomeTestimonial from "./components/CommonHomePage/HomeTestimonial/HomeTestimonial.jsx";
import HomeFooter from "./components/CommonHomePage/HomeFooter/HomeFooter.jsx";
import StickyFooter from "./components/CommonHomePage/StickyFooter/StickyFooter.jsx";
import "./LandingPage.css";
import ConsentFormPrompt from "./CommonFiles/ConsentFormPrompt.jsx";


const LandingPage = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [openConsentPrompt, setOpenConsentPrompt] = useState(false);

  const handleToggleDarkMode = (darkMode) => {
    setIsDarkMode(darkMode);
  };

  const checkCookieConsent = () => {
    const key = "lcCCFst";
    
    const hasConsented = localStorage.getItem(key) === "true";

    if(!hasConsented){
      setOpenConsentPrompt(true);
    }
  };

  const handleCloseConsentPrompt = () => {
      setOpenConsentPrompt(false);
  };

  useEffect(() => {
    checkCookieConsent();
  }, []);

  return (
    <div className={`common-homepage ${isDarkMode ? "dark" : ""}`}>
      <ConsentFormPrompt open={openConsentPrompt} handleClose={handleCloseConsentPrompt} />
      <HomeNavbar onToggleDarkMode={handleToggleDarkMode} />
      <div id="home-section">
        <HomeHero isDarkMode={isDarkMode} />
      </div>
      <HomeAbout isDarkMode={isDarkMode} />
      <div id="solutions-section">
        <HomeTools isDarkMode={isDarkMode} />
      </div>
      <HomeTestimonial isDarkMode={isDarkMode} />
      <div id="contacts-section">
        <HomeFooter isDarkMode={isDarkMode} />
      </div>
      <StickyFooter isDarkMode={isDarkMode} />
    </div>
  );
};

export default LandingPage;
