import React from "react";
import "./SocialMedia.css";

const SocialMedia = ({ isDarkMode }) => {
  return (
    <>
      <div className={`socail_media ${isDarkMode ? "dark-mode" : ""}`}>
        <div className="socail_top">
          <p>Social Media</p>
        </div>
        <div className="social_bot">
          <a href="https://x.com/LawcratsTeam" className="icon-a">
            <i class="fa-brands fa-x-twitter"></i>
          </a>
          <a href="https://www.instagram.com/lawcrats/" className="icon-a">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.facebook.com/people/LawCrats-The-NextGen-Techno-Lawyers/61559933638363/" className="icon-a">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
        </div>
        <div className="bottom_line"></div>
      </div>
    </>
  );
};

export default SocialMedia;
