import React from "react";
import Sidebar from "../../src/components/ComponentCaseSummariser/Sidebar/Sidebar";
import CaseSummariser from "../CaseSummariserPage/CaseSummariser/CaseSummariser";
import ContextProvider from "./Context/Context";

const CaseSummariserPage = () => {
  return (
    <>
      <ContextProvider>
        <div className="case_Summariser_main" style={{ display: "flex" }}>
          <Sidebar />
          <CaseSummariser />
        </div>
      </ContextProvider>
    </>
  );
};

export default CaseSummariserPage;
