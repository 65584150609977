import React, { useContext, useState, useEffect } from "react";
import { assets } from "../../../assets/assets";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton } from "@mui/material";
import { Context } from "../../../CaseSummariserPage/Context/Context";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [extended, setExtended] = useState(false);
  const { newChat, allChatHistory, loadChatSession, setAllChatHistory } = useContext(Context);
  const navigate = useNavigate();

  const handleUpgrade = () => {
    navigate("/upgrade-plan");
  };

  const handleChatClick = (index) => {
    loadChatSession(index);
    navigate("/casesummariser", { state: { summary: allChatHistory[index].summary, pdfName: allChatHistory[index].pdfName } });
  };
  

  useEffect(() => {
    console.log("Chat History:", allChatHistory);
  }, [allChatHistory]);

  return (
    <div className="sidebar">
      <div className="top">
        <img
          src={assets.menu_icon}
          className="menu"
          alt=""
          onClick={() => setExtended((prev) => !prev)}
        />
        <div onClick={() => newChat()} className="sidebar-button" style={{ marginTop: "40px" }}>
          <QuestionAnswerOutlinedIcon />
          {extended ? <p>New Chat</p> : null}
        </div>
        {extended ? (
          <div className="recent">
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <hr style={{ border: "none", borderTop: "1.5px solid #E0DDDD" }} />
              <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", paddingLeft: "10px" }}>
                <p className="recent-title" style={{ color: "#808080", cursor: "pointer" }}>
                  Your Summary
                </p>
                <IconButton onClick={() => setAllChatHistory([])}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>
              <hr style={{ border: "none", borderTop: "1.5px solid #E0DDDD" }} />
            </div>
            <div className="chatHistoryContainer" style={{ display: "flex", flexDirection: "column", overflowY: "auto", maxHeight: "235px" }}>
              {allChatHistory.length > 0 &&
                allChatHistory.map((chat, index) => (
                  <div
                    className="sidebar-button"
                    key={index}
                    onClick={() => handleChatClick(index)}
                  >
                    <img src={assets.message_icon} alt="" />
                    <p>{chat.pdfName || "Untitled PDF"}</p>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className="bottom">
        <div className="sidebar-button">
          <SettingsOutlinedIcon />
          {extended ? <p style={{ color: "#808080" }}>Settings</p> : null}
        </div>
        <div className="sidebar-button" onClick={handleUpgrade}>
          <DiamondOutlinedIcon />
          {extended ? <p style={{ color: "#808080" }}>Upgrade to Premium</p> : null}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
