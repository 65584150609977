import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const Context = createContext();

const ContextProvider = (props) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [allChatHistory, setAllChatHistory] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const token = localStorage.getItem("lawCratsToken");

  useEffect(() => {
    if (token) {
      loadChatHistoryFromBackend();
    }
  }, [token]);

  const loadChatHistoryFromBackend = async () => {
    try {
      const response = await axios.get(
        "https://d3gcxrg9olqhec.cloudfront.net/legal-solutions/lawchatbot-history/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const transformedData = response.data.map((session) => ({
        date: session.session_created_at,
        sessionChatHistory: session.threads,
      }));

      console.log("history successfully loaded:", transformedData);
      setAllChatHistory(transformedData);
    } catch (error) {
      console.error("error fetching history:", error);
    }
  };

  const newChat = () => {
    if (chatHistory.length > 1) {
      saveChatHistory("is_new_chat : true");
    }
    // setCurrentSessionIndex(null);
  };

  const saveChatHistory = async () => {
    const newChatToStore = {
      date: new Date().toLocaleDateString(),
      sessionChatHistory: chatHistory,
    };
    setChatHistory((prevHistories) => [...prevHistories, newChatToStore]);
    setChatHistory([{}]);
  };

  const loadChatSession = (selectedChatSession) => {
    setChatHistory([]); // Clear existing chat history
    setSelectedChat(selectedChatSession); // Update the selected chat
    setChatHistory(selectedChatSession ? [selectedChatSession] : []); // Load only this session's history
  };

  useEffect(() => {
  }, [allChatHistory]);

  const contextValue = {
    chatHistory,
    setChatHistory,
    allChatHistory,
    setAllChatHistory,
    newChat,
    loadChatSession,
    selectedChat,
  };

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};

export default ContextProvider;
