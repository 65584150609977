import React from "react";
import "./HomeTools.css";
import { useNavigate } from "react-router-dom";
import { FaRobot, FaSearch, FaFileSignature } from "react-icons/fa";
import { Link } from "react-router-dom";

const Hometools = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const handleLCBRediret = () => {
    navigate("/law-chat-bot");
  };

  return (
    <div className={`hometools-section ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="hometools-background">
        <div className="hometools-content">
          <div className="hometools-header">
            <h3 className="header-title">Our Solutions</h3>
          </div>
          <div className="hometools-cards">
            <div className="card2" onClick={handleLCBRediret}>
              <FaRobot size={50} color="#FFFFFF"/>
              <h4 className="card-title">LawCrats Legal Assistant</h4>
              <div className="beta-badge">Beta</div>
              <div className="card-description">
                <div className="card_description_heading">
                  <h1>LawCrats Legal Assistant</h1>
                  <FaRobot size={50} color="#000000" style={{paddingRight:'20px'}} />
                </div>
                <p>
                The only AI-driven chatbot that truly understands your legal queries. Get detailed, well-reasoned answers to your legal questions, tailored specifically to your needs.
                </p>
              </div>
            </div>
            <Link to={"/casesearch"}>
              <div className="card2">
                <FaSearch size={50} color="#FFFFFF" />
                <h4 className="card-title">LawCrats Legal Explorer</h4>
                <div className="beta-badge">Beta</div>
                <div className="card-description">
                  <div className="card_description_heading">
                    <h1>LawCrats Legal Explorer</h1>
                    <FaSearch size={50} color="#000000" style={{paddingRight:'20px'}}/>
                  </div>
                  <p>
                  Your one-stop solution for finding any legal document you need. With unparalleled search capabilities, find judgments, acts, and notifications effortlessly and get instant summaries right alongside.
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/casesummariser"}>
              <div className="card2">
                <FaFileSignature size={50} color="#FFFFFF" />
                <h4 className="card-title">LawCrats Summarizer</h4>
                <div className="beta-badge">Beta</div>
                <div className="card-description">
                  <div className="card_description_heading">
                    <h1>LawCrats Summarizer</h1>
                    <FaFileSignature size={50} color="#000000" style={{paddingRight:'20px'}}/>
                  </div>
                  <p>
                  The only tool you'll ever need to turn lengthy legal documents into concise, expert summaries in seconds. No more endless reading—get to the essence faster than ever.
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/new-rad"}>
              <div className="card2">
                <FaFileSignature size={50} color="#FFFFFF"/>
                <h4 className="card-title">LawCrats Draftmaster</h4>
                <div className="beta-badge">Beta</div>
                <div className="card-description">
                  <div className="card_description_heading">
                    <h1>LawCrats DraftMaster</h1>
                    <FaFileSignature size={50} color="#000000" style={{paddingRight:'20px'}} />
                  </div>
                  <p>
                  The ultimate tool for drafting legal documents in record time. Just input your details, and let our AI create a fully customized, professional document for you—no hassle, no delay.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <button className="more-services-button">More Services</button>
        </div>
      </div>
    </div>
  );
};

export default Hometools;
