import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import lawCratsIconBlack from "../assets/LCLogoBlack.png";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    dialog: {
        backdropFilter: 'blur(5px)',
    },
    dialogPaper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        minWidth: '300px',
        minHeight: '200px',
        '@media (max-width: 600px)': { 
            minWidth: '90%', 
            padding: '8px', 
        },
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 2,
    },
    liObj: {
        fontSize: "12pt",
        lineHeight: "1.5",
        overflowWrap: "break-word",
        marginBottom: "10px",
        textAlign: "justify",
        textJustify: "inter-word",
        fontFamily: "Times New Roman",
        '@media (max-width: 600px)': { 
            fontSize: "9pt", 
        },
    },
    dialogTitle: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 600px)': { 
            textAlign: 'center',
        },
    },
    dialogTitleText: {
        marginLeft: '10px',
        '@media (max-width: 600px)': { 
            marginLeft: '0', 
        },
    },
    logo: {
        width: '40px',
        '@media (max-width: 600px)': { 
            width: '30px', 
        },
    },
});

const TermsOfServiceModal = ({ open, handleClose }) => {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="terms-of-service-title" className={classes.dialog} PaperProps={{ className: classes.dialogPaper }}>
            <DialogTitle style={{ width: "100%" }}>
                <div className={classes.dialogTitle}>
                    <img src={lawCratsIconBlack} alt="" className={classes.logo} />
                    <h4 className={classes.dialogTitleText}>Terms of Service</h4>
                    <IconButton className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <ul>
                    <li className={classes.liObj}>
                        <h2>Introduction</h2>
                        Welcome to LawCrats’s website! By accessing and using our website, you agree to comply with these Terms of Service. Please read them carefully.
                    </li>
                    <li className={classes.liObj}>
                        <h2>Acceptance of Terms</h2>
                        By using our website, you acknowledge that you have read, understood, and agreed to these terms. If you do not agree, please refrain from using our services.
                    </li>
                    <li className={classes.liObj}>
                        <h2>Services Offered</h2>
                        <h5>Copyright:</h5> All content on our website, including text, images, videos, and graphics, is protected by copyright laws. You may not reproduce, distribute, or modify any content without our permission.
                        <h5>License:</h5> We grant you a limited, non-exclusive, revocable license to access and use our website for personal, non-commercial purposes.
                    </li>
                    <li className={classes.liObj}>
                        <h2>Disclaimer</h2>
                        <h5>Legal Advice:</h5> We provide legal advice, opinions, and guidance based on our expertise. However, our advice does not guarantee specific outcomes, as legal matters are subject to various factors.
                        <h5>Accuracy:</h5> While we strive for accuracy, we do not guarantee the completeness or correctness of the content. Use it at your own risk.
                    </li>
                    <li className={classes.liObj}>
                        <h2>Privacy Policy</h2>
                        Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our website, you consent to our privacy practices as described in the Privacy Policy.
                    </li>
                    <li className={classes.liObj}>
                        <h2>Limitation of Liability</h2>
                        We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of our website.
                    </li>
                    <li className={classes.liObj}>
                        <h2>Governing Law</h2>
                        These Terms of Service are governed by the laws of India. Any disputes will be subject to the exclusive jurisdiction of the courts in Ghaziabad, Uttar Pradesh.
                    </li>
                    <li className={classes.liObj}>
                        <h2>Contact Us</h2>
                        If you have any questions or concerns regarding these terms, please contact us at support@lawcrats.com
                    </li>
                </ul>
            </DialogContent>
            <DialogActions>
                support@lawcrats.com
            </DialogActions>
        </Dialog>
    );
};

export default TermsOfServiceModal;
