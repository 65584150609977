import React, { useContext, useState } from "react";
import { assets } from "../../../assets/assets";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton } from "@mui/material";
import { Context } from "../../../CaseSearchpage/context/Context";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [Extended, setExtended] = useState(false);
  const { newChat, allChatHistory, loadChatSession, setAllChatHistory } =
    useContext(Context);
  const navigate = useNavigate();

  const handleUpgrade = () => {
    navigate("/upgrade-plan");
  };

  const getLimitedTitle = (title) => {
    return title && title.length > 18 ? title.slice(0, 18) + "..." : title;
  };

  const handleChatClick = (index) => {
    const selectedChat = allChatHistory[index];

    if (selectedChat) {
      loadChatSession(index);

      navigate("/casesearch", {
        state: {
          caseData: {
            case_title: selectedChat.case_title || "N/A",
            case_no: selectedChat.case_no || "N/A",
            judges: selectedChat.judges || "N/A",
            disposal_nature: selectedChat.disposal_nature || "N/A",
            decision_date: selectedChat.decision_date || "N/A",
            pdf_link: selectedChat.pdf_link || null,
            summary:
              selectedChat.summary || "No summary available for this case.",
          },
        },
      });
    }
  };

  return (
    <div className="sidebar">
      <div className="top">
        <img
          src={assets.menu_icon}
          className="menu"
          alt=""
          onClick={() => setExtended((prev) => !prev)}
        />
        <div
          onClick={() => newChat()}
          className="sidebar-button"
          style={{ marginTop: "40px" }}
        >
          <QuestionAnswerOutlinedIcon />
          {Extended ? <p>New Chat</p> : null}
        </div>
        {Extended ? (
          <div className="recent">
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <hr
                style={{ border: "none", borderTop: "1.5px solid #E0DDDD" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <p
                  className="recent-title"
                  style={{ color: "#808080", cursor: "pointer" }}
                >
                  Your Summary
                </p>
                <IconButton onClick={() => setAllChatHistory([])}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>
              <hr
                style={{ border: "none", borderTop: "1.5px solid #E0DDDD" }}
              />
            </div>
            <div
              className="chatHistoryContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                maxHeight: "235px",
              }}
            >
              {allChatHistory && allChatHistory.length > 0 ? (
                allChatHistory.map((chat, index) => (
                  <div
                    className="sidebar-button"
                    key={index}
                    onClick={() => handleChatClick(index)}
                  >
                    <img src={assets.message_icon} alt="" />
                    <p>{getLimitedTitle(chat.case_title || "Untitled Case")}</p> {/* Use the limited title function */}
                  </div>
                ))
              ) : (
                <p>No recent chats found.</p>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div className="bottom">
        <div className="sidebar-button">
          <SettingsOutlinedIcon />
          {Extended ? <p style={{ color: "#808080" }}>Settings</p> : null}
        </div>
        <div className="sidebar-button" onClick={handleUpgrade}>
          <DiamondOutlinedIcon />
          {Extended ? (
            <p style={{ color: "#808080" }}>Upgrade to Premium</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
