import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const Context = createContext();

const ContextProvider = (props) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [allChatHistory, setAllChatHistory] = useState([]);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(null);
  const token = localStorage.getItem("lawCratsToken");

  useEffect(() => {
    if (token) {
      loadChatHistoryFromBackend();
    }
  }, [token]);

  const loadChatHistoryFromBackend = async () => {
    try {
      const response = await axios.get(
        "https://d3gcxrg9olqhec.cloudfront.net/legal-solutions/case-summarizer-history/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && Array.isArray(response.data)) {
        const chatHistoryData = response.data.map((session) => ({
          caseLink: session.case_link,
          pdfName: session.pdf_name,
          summary: session.summary,
          time: session.time,
        }));
        setAllChatHistory(chatHistoryData);
      } else {
        console.error("Unexpected data format in response:", response.data);
      }
    } catch (error) {
      console.error("Error loading chat history:", error);
    }
  };

  const newChat = () => {
    if (chatHistory.length > 0) {
      saveChatHistory(() => {});
    }
    setChatHistory([]);
    setCurrentSessionIndex(null);
  };

  const saveChatHistory = async (callback) => {
    if (chatHistory.length > 0) {
      const sessionData = {
        date: new Date().toLocaleString(),
        fileName: chatHistory[0]?.question || "Untitled PDF",
        sessionChatHistory: chatHistory,
        firstQuestion: chatHistory[0]?.question || "No question",
      };
      try {
        await axios.post(
          "https://d3gcxrg9olqhec.cloudfront.net/legal-solutions/case-summarizer/",
          { chatHistory: sessionData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAllChatHistory((prevHistory) => [sessionData, ...prevHistory]);
        setChatHistory([]);
        if (callback) callback();
      } catch (error) {
        console.error("Error saving chat history:", error);
      }
    }
  };

  const loadChatSession = (index) => {
    setChatHistory(allChatHistory[index]?.sessionChatHistory || []);
    setCurrentSessionIndex(index);
  };

  const contextValue = {
    chatHistory,
    setChatHistory,
    allChatHistory,
    setAllChatHistory,
    newChat,
    saveChatHistory,
    loadChatSession,
  };

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};

export default ContextProvider;
