import axios from "axios";
import { createContext, useState, useEffect } from "react";

export const Context = createContext();

const ContextProvider = (props) => {
  const [chatHistory, setChatHistory] = useState([{}]);
  const [allChatHistory, setAllChatHistory] = useState([]);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(null);
  const token = localStorage.getItem("lawCratsToken");

  useEffect(() => {
    if (token) {
      fetchHistoryFromUrl();
    }
  }, [token]);

  const newChat = () => {
    if (chatHistory.length > 1) {
      saveChatHistory();
    }
    setCurrentSessionIndex(null);
  };

  const saveChatHistory = () => {
    const newChatToStore = {
      date: new Date().toLocaleDateString(),
      sessionChatHistory: [chatHistory],
    };
    setAllChatHistory((prevHistories) => [...prevHistories, newChatToStore]);
    setChatHistory([{}]);
  };

  const fetchHistoryFromUrl = async () => {
    try {
      const response = await axios.get(
        "https://d3gcxrg9olqhec.cloudfront.net/legal-solutions/case-search-history/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setAllChatHistory(response.data);
    } catch (error) {
      console.error("Error fetching history:", error);
    }
  };

  const loadChatSession = (index) => {
    setChatHistory(allChatHistory[index]?.sessionChatHistory || []);
    setCurrentSessionIndex(index);
  };

  const ContextValue = {
    chatHistory,
    setChatHistory,
    allChatHistory,
    setAllChatHistory,
    newChat,
    loadChatSession,
  };

  return (
    <Context.Provider value={ContextValue}>{props.children}</Context.Provider>
  );
};

export default ContextProvider;
