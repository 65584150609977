import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import lawCratsIconBlack from "../assets/LCLogoBlack.png";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  dialog: {
    backdropFilter: 'blur(5px)',
  },
  dialogPaper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    minWidth: '300px',
    minHeight: '200px',
    '@media (max-width: 600px)': { // For small screens
      minWidth: '90%', // Expand width to 90% on smaller screens
      padding: '8px', // Reduce padding
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 2,
  },
  liObj: {
    fontSize: "12pt",
    lineHeight: "1.5",
    overflowWrap: "break-word",
    marginBottom: "10px",
    textAlign: "justify",
    textJustify: "inter-word",
    fontFamily: "Times New Roman",
    '@media (max-width: 600px)': { // For small screens
      fontSize: '9pt', // Reduce font size on smaller screens
    },
  },
  dialogTitle: {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 600px)': { 
      textAlign: 'center',
    },
  },
  dialogTitleText: {
    marginLeft: '10px',
    '@media (max-width: 600px)': { // For small screens
      marginLeft: '0', // Remove margin on small screens
    },
  },
  logo: {
    width: '40px',
    '@media (max-width: 600px)': { // For small screens
      width: '30px', // Reduce logo size on smaller screens
    },
  },
});

const PrivacyPolicyModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="privacy-policy-title"
      className={classes.dialog}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle style={{ width: "100%" }}>
        <div className={classes.dialogTitle}>
          <img src={lawCratsIconBlack} alt="" className={classes.logo} />
          <h4 className={classes.dialogTitleText}>Privacy Policy</h4>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent dividers>
        <ul>
          <li className={classes.liObj}>
            <h2>Introduction</h2> 
            Welcome to LawCrats's website! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website.
          </li>
          <li className={classes.liObj}>
            <h2>Information We Collect</h2>   
            <h5>Personal Information</h5>
            We may collect the following types of personal information: 
            <h5>Contact Information:</h5> When you fill out our contact form or subscribe to our newsletter, we collect your name, email address, phone number, and other relevant details.
            Usage Data: We automatically collect information about your interaction with our website, including IP addresses, browser type, pages visited, and referral sources.
          </li>
          <li className={classes.liObj}>
            <h2>Cookies</h2> 
            We use cookies to enhance your browsing experience. Cookies are small text files stored on your device that help us analyze website traffic and improve our services. You can adjust your browser settings to manage cookies or opt-out if you prefer.
            We mainly use four types of cookies (customizable)- 

            <h5>Technical Cookies</h5>
            Technical cookies are essential for the proper functioning of our website. They enable core functionalities such as page navigation, security, and session management. 
            These cookies are necessary for you to browse the site and use its features effectively.
            
            <h5>Marketing Cookies</h5>
            Marketing cookies help us deliver personalized advertising and promotional content based on your interests and browsing behavior. 
            These cookies allow us to show you relevant ads and measure the effectiveness of our marketing campaigns. Disabling marketing cookies may result in seeing less relevant advertisements.

            <h5>Analytical Cookies</h5>
            Analytical cookies help us understand how visitors interact with our website by collecting anonymous data. These cookies enable us to analyze website 
            traffic, identify trends, and improve the overall user experience. Disabling analytical cookies may limit our ability to make improvements based on user feedback.

            <h5>Functional Cookies</h5>
            Functional cookies are used to enhance your experience on our website by enabling core functionalities such as language preferences, login sessions, 
            or other personalized features. These cookies are essential for certain website features to work properly and may impact your experience if disabled.
            
          </li>
          <li className={classes.liObj}>
            <h2>How We Use Your Information</h2> 
            <h5>Communication:</h5> We use your contact information to respond to your inquiries, provide legal updates, and send newsletters (if you’ve subscribed).
            <h5>Analytics:</h5> We analyze usage data to improve our website’s functionality, content, and user experience.

          </li>
          <li className={classes.liObj}>
             <h2>Data Security</h2> 
            We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet is entirely secure, so we cannot guarantee absolute security.
          </li>
          <li className={classes.liObj}>
             <h2>Third-Party Services</h2> 
            Our website may contain links to third-party websites or services. Please review their privacy policies as we are not responsible for their practices.
          </li>
          <li className={classes.liObj}>
              <h2>Your Rights</h2> 
            You have the right to:<br/>

            Access, update, or delete your personal information.
            Opt-out of receiving marketing communications.
            Request a copy of the data we hold about you.

          </li>
          <li className={classes.liObj}>
            <h2>Consent</h2> 
            By using our website and clicking the “Accept” button, you agree to our Privacy Policy, Legal Services Disclosure and consent to the collection and use of your information as described herein.
          </li>
          <li className={classes.liObj}>
          <h2>Changes to this Policy</h2> 
            We may update this Privacy Policy periodically. Any changes will be posted on this page.
          </li>
          <li className={classes.liObj}>
            <h2>Contact Us</h2>
            If you have any questions or concerns about our Privacy Policy, please contact us at support@lawcrats.com
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        support@lawcrats.com
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPolicyModal;
