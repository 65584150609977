import React, { useState } from "react";
import HomeHero from "./components/CommonHomePage/HomeHero/HomeHero.jsx";
import HomeAbout from "./components/CommonHomePage/HomeAbout/HomeAbout.jsx";
import HomeTools from "./components/CommonHomePage/HomeTools/HomeTools.jsx";
import HomeTestimonial from "./components/CommonHomePage/HomeTestimonial/HomeTestimonial.jsx";
import HomeFooter from "./components/CommonHomePage/HomeFooter/HomeFooter.jsx";
import SocialMedia from "./components/CommonHomePage/SocailMedia/SocialMedia.jsx";
import StickyFooter from "./components/CommonHomePage/StickyFooter/StickyFooter.jsx";
import HomeNavbar from "./components/CommonHomePage/HomeNavbar/HomeNavbar.jsx";

const HomePage = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleToggleDarkMode = (darkMode) => {
    setIsDarkMode(darkMode);
  };

  return (
    <div className={`common-homepage ${isDarkMode ? "dark" : ""}`}>
      <HomeNavbar onToggleDarkMode={handleToggleDarkMode}/>
      <HomeHero isDarkMode={isDarkMode} />
      <HomeAbout isDarkMode={isDarkMode} />
      <HomeTools isDarkMode={isDarkMode} />
      <HomeTestimonial isDarkMode={isDarkMode} />
      <HomeFooter isDarkMode={isDarkMode} />
      <SocialMedia isDarkMode={isDarkMode} />
      <StickyFooter isDarkMode={isDarkMode} />
    </div>
  );
};

export default HomePage;
