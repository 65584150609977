import React, { useState, useEffect } from 'react';
import './WordCarousel.css';
import "./HomeHero.css";
import img from "../../../assets/Lawcrtas logo final.png";
import img6 from "../../../assets/white version.png";
import t1 from "../../../assets/t1.png";
import t2 from "../../../assets/t2.png";
import t3 from "../../../assets/t3.png";
import t4 from "../../../assets/t4.png";
import t5 from "../../../assets/t5.png";
import t6 from "../../../assets/t6.png";
import t7 from "../../../assets/t7.png";
import t8 from "../../../assets/t8.png";
import t9 from "../../../assets/t9.png";
import t10 from "../../../assets/t10.png";
import t11 from "../../../assets/t11.png";
import t12 from "../../../assets/t12.png";
import t13 from "../../../assets/t13.png";
import t14 from "../../../assets/t14.png";

const HomeHero = ({ isDarkMode }) => {

  const [activeIndex, setActiveIndex] = useState(null);

  const handleScrollToSection = (sectionId, index) => {
    setActiveIndex(index);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const words = ['Compliance', 'Analytics', 'Automation', 'Research', 'Drafting', 'Jurimetrics'];

  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500); // Change every 1.5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [words.length]);

  return (
    <div className={`homehero ${isDarkMode ? "dark" : ""}`}>
      <div className="homehero_content">
        <img src={isDarkMode ? img6 : img} alt="search icon" />
        <p className="heading">
          <span className="fixed-text">We are Expert in</span>
          <span key={currentWordIndex} className="word">
            {words[currentWordIndex]}
          </span>
        </p>

        <p className="content">
          LawCrats is here to help you overcome the challenges of modern legal work. We ensure smoother workflows, allowing you to be more productive and confident in your decisions. Trust us to elevate your legal capabilities in a competitive world.
        </p>
      </div>
      <div className="hh-parent-div">
        <div className="hh-button-div">Supported By</div>
      </div>
      <div className="logo-container">
        <div className="logo-slider">

          <div className="logo-item" style={{ marginTop: '10px' }}>
            <img className="t1" src={t1} alt="Logo 1" />
          </div>
          <div className="logo-item" style={{ marginTop: '12px' }}>
            <img className="t2" src={t2} alt="Logo 2" />
          </div>
          <div className="logo-item">
            <img className="t3" src={t3} alt="Logo 3" />
          </div>
          <div className="logo-item">
            <img className="t4" src={t4} alt="Logo 4" />
          </div>
          <div className="logo-item">
            <img className="t5" src={t5} alt="Logo 5" />
          </div>
          <div className="logo-item">
            <img className="t6" src={t6} alt="Logo 6" />
          </div>
          <div className="logo-item">
            <img className='t7' src={t7} alt="Logo 7" />
          </div>
          <div className="logo-item">
            <img className='t8' src={t8} alt="Logo 8" />
          </div>
          <div className="logo-item">
            <img className="t9" src={t9} alt="Logo 9" />
          </div>
          <div className="logo-item">
            <img src={t10} alt="Logo 10" />
          </div>
          <div className="logo-item">
            <img className="t11" src={t11} alt="Logo 11" />
          </div>
          <div className="logo-item">
            <img className="t12" src={t12} alt="Logo 12" />
          </div>
          <div className="logo-item">
            <img className='t13' src={t13} alt="Logo 13" />
          </div>
          <div className="logo-item">
            <img className='t14' src={t14} alt="Logo 14" />
          </div>

          <div className="logo-item" style={{ marginTop: '10px' }}>
            <img className="t1" src={t1} alt="Logo 1" />
          </div>
          <div className="logo-item" style={{ marginTop: '12px' }}>
            <img className="t2" src={t2} alt="Logo 2" />
          </div>
          <div className="logo-item">
            <img className="t3" src={t3} alt="Logo 3" />
          </div>
          <div className="logo-item">
            <img className="t4" src={t4} alt="Logo 4" />
          </div>
          <div className="logo-item">
            <img className="t5" src={t5} alt="Logo 5" />
          </div>
          <div className="logo-item">
            <img className="t6" src={t6} alt="Logo 6" />
          </div>
          <div className="logo-item">
            <img className='t7' src={t7} alt="Logo 7" />
          </div>
          <div className="logo-item">
            <img className='t8' src={t8} alt="Logo 8" />
          </div>
          <div className="logo-item">
            <img className="t9" src={t9} alt="Logo 9" />
          </div>
          <div className="logo-item">
            <img src={t10} alt="Logo 10" />
          </div>
          <div className="logo-item">
            <img className="t11" src={t11} alt="Logo 11" />
          </div>
          <div className="logo-item">
            <img className="t12" src={t12} alt="Logo 12" />
          </div>
          <div className="logo-item">
            <img className='t13' src={t13} alt="Logo 13" />
          </div>
          <div className="logo-item">
            <img className='t14' src={t14} alt="Logo 14" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;

