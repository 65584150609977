import React, {useState} from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import lawCratsIconBlack from "../assets/LCLogoBlack.png";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Switch, Collapse } from '@mui/material';


const useStyles = makeStyles({
    dialog: {
      backdropFilter: 'blur(5px)',
    },
    dialogPaper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px',
      minWidth: '300px',
      minHeight: '200px',
      '@media (max-width: 600px)': {
        minWidth: '90%',
        padding: '8px',
      },
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 2,
    },
    liObj: {
      fontSize: "12pt",
      lineHeight: "1.5",
      overflowWrap: "break-word",
      marginBottom: "10px",
      textAlign: "justify",
      textJustify: "inter-word",
      fontFamily: "Times New Roman",
      '@media (max-width: 600px)': {
        fontSize: '9pt',
      },
    },
    dialogTitle: {
      width: "100%",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media (max-width: 600px)': { 
        textAlign: 'center',
      },
    },
    dialogTitleText: {
      marginLeft: '10px',
      '@media (max-width: 600px)': {
        marginLeft: '0',
      },
    },
    logo: {
      width: '40px',
      '@media (max-width: 600px)': {
        width: '30px',
      },
    },
  });

const ManagePreferenceModal = ({open, handleClose, cookieAcceptance, setCookieAcceptance, }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState([false, false, false, false]);

    const handleToggle = (index) => {
        setIsExpanded((prevState) =>
          prevState.map((expanded, i) => (i === index ? !expanded : expanded))
        );
    };

    const handleSwitchChange = (index) => {
        setCookieAcceptance(prevState => {
            const newState = [...prevState];
            newState[index] = !prevState[index];
            return newState;
        });
    };

    const handleAcceptAllFunctionality = () => {
        setCookieAcceptance(prevState => {
            const newState = Array(cookieAcceptance.length).fill(true);
            return newState; 
        });
    }

    const handleRejectALLFunctionality = () => {
        setCookieAcceptance(prevState => {
            const newState = [...prevState];
            for (let i = 1; i < newState.length; i++) {
                newState[i] = false;
            }
            return newState;
        });
    }

    const handleSaveAndContinue = () => {
        handleClose();
    };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle style={{ width: "100%" }}>
        <div className={classes.dialogTitle}>
          <img src={lawCratsIconBlack} alt="" className={classes.logo} style={{width: '25px'}}/>
          <h4 className={classes.dialogTitleText}>Manage Your Preference</h4>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <p className={classes.liObj}>
        We use cookies to enhance your browsing experience. You can modify your settings at any time, 
        but please be aware that restricting certain cookies could affect the functionality of the 
        site and the quality of our services.
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#333' }}>Technical Cookies*</span>
                        <IconButton onClick={() => handleToggle(0)}>
                            <ExpandMoreIcon sx={{ transform: isExpanded[0] ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s', }} />
                        </IconButton>
                    </div>
                    <Switch disabled defaultChecked sx={{ '& .MuiSwitch-track': { backgroundColor: '#b0b0b0', }, '& .MuiSwitch-thumb': { backgroundColor: '#6a6c6d',},}} />
                </div>
                <Collapse in={isExpanded[0]} timeout="auto" unmountOnExit>
                    <i style={{fontSize: '12px', color: '#555', fontWeight: '600'}}>* This is mandatory and can't be disabled.</i>
                    <div style={{ marginTop: '8px', fontSize: '14px', color: '#555', textJustify: "inter-word", overflowWrap: "break-word", textAlign: "justify", fontFamily: "Times New Roman",}}>
                    Technical cookies are essential for the proper functioning of our website. They enable core functionalities such as page navigation, security, and session management. 
                    These cookies are necessary for you to browse the site and use its features effectively.
                    </div>
                </Collapse>
            </div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#333' }}>Marketing Cookies</span>
                        <IconButton onClick={() => handleToggle(1)}>
                            <ExpandMoreIcon sx={{ transform: isExpanded[1] ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s', }} />
                        </IconButton>
                    </div>
                    <Switch checked={cookieAcceptance[1]} onChange={() => handleSwitchChange(1)} sx={{ '& .MuiSwitch-track': { backgroundColor: '#b0b0b0', }, '& .MuiSwitch-thumb': { backgroundColor: '#6a6c6d',},}} />
                </div>
                <Collapse in={isExpanded[1]} timeout="auto" unmountOnExit>
                    <div style={{ marginTop: '8px', fontSize: '14px', color: '#555', textJustify: "inter-word", overflowWrap: "break-word", textAlign: "justify", fontFamily: "Times New Roman",}}>
                    Marketing cookies help us deliver personalized advertising and promotional content based on your interests and browsing behavior. 
                    These cookies allow us to show you relevant ads and measure the effectiveness of our marketing campaigns. Disabling marketing cookies may result in seeing less relevant advertisements.
                    </div>
                </Collapse>
            </div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#333' }}>Analytical Cookies</span>
                        <IconButton onClick={() => handleToggle(2)}>
                            <ExpandMoreIcon sx={{ transform: isExpanded[2] ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s', }} />
                        </IconButton>
                    </div>
                    <Switch checked={cookieAcceptance[2]} onChange={() => handleSwitchChange(2)} sx={{ '& .MuiSwitch-track': { backgroundColor: '#b0b0b0', }, '& .MuiSwitch-thumb': { backgroundColor: '#6a6c6d',},}} />
                </div>
                <Collapse in={isExpanded[2]} timeout="auto" unmountOnExit>
                    <div style={{ marginTop: '8px', fontSize: '14px', color: '#555', textJustify: "inter-word", overflowWrap: "break-word", textAlign: "justify", fontFamily: "Times New Roman",}}>
                    Analytical cookies help us understand how visitors interact with our website by collecting anonymous data. These cookies enable us to analyze website 
                    traffic, identify trends, and improve the overall user experience. Disabling analytical cookies may limit our ability to make improvements based on user feedback.
                    </div>
                </Collapse>
            </div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span style={{ fontSize: '14px', fontWeight: '500', color: '#333' }}>Functional Cookies</span>
                        <IconButton onClick={() => handleToggle(3)}>
                            <ExpandMoreIcon sx={{ transform: isExpanded[3] ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s', }} />
                        </IconButton>
                    </div>
                    <Switch checked={cookieAcceptance[3]} onChange={() => handleSwitchChange(3)} sx={{ '& .MuiSwitch-track': { backgroundColor: '#b0b0b0', }, '& .MuiSwitch-thumb': { backgroundColor: '#6a6c6d',},}} />
                </div>
                <Collapse in={isExpanded[3]} timeout="auto" unmountOnExit>
                    <div style={{ marginTop: '8px', fontSize: '14px', color: '#555', textJustify: "inter-word", overflowWrap: "break-word", textAlign: "justify", fontFamily: "Times New Roman",}}>
                    Functional cookies are used to enhance your experience on our website by enabling core functionalities such as language preferences, login sessions, 
                    or other personalized features. These cookies are essential for certain website features to work properly and may impact your experience if disabled.
                    </div>
                </Collapse>
            </div>
        </div>

      </DialogContent>
      <DialogActions style={{ width: "100%" }}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '50%'}}>
                <Button variant='contained' onClick={handleAcceptAllFunctionality} sx={{ textTransform: 'none', backgroundColor: '#6a6c6d', '@media (max-width: 600px)': {fontSize: '12px', padding: '6px 12px',},}}>Accept all cookies</Button>
                <Button onClick={handleRejectALLFunctionality} sx={{textTransform: 'none', color: 'red', outline: '1px solid #6a6c6d', marginLeft: '10px', '@media (max-width: 600px)': {fontSize: '12px', padding: '6px 12px',},}}>Reject all</Button>
            </div>
            <Button onClick={handleSaveAndContinue} sx={{textTransform: 'none', outline: '1px solid #6a6c6d', '@media (max-width: 600px)': {fontSize: '12px', padding: '6px 12px',},}}>Save & Continue</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ManagePreferenceModal