import React, { useEffect, useState, useRef } from "react";
import ProfileIconDropDown from "../CommonFiles/ProfileIconDropdown.jsx";
import "./Upgrade.css";
import { assets } from "../assets/assets.js";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UpgradePlan = () => {
  const navigate = useNavigate();
  const [showPIDropdown, setShowPIDropdown] = useState(false);

  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navRef.current &&
        !navRef.current.contains(event.target) &&
        !event.target.matches(".case-search-nav-img")
      ) {
        if (showPIDropdown) {
          setShowPIDropdown(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPIDropdown]);

  const toggleDropdown = () => {
    setShowPIDropdown(!showPIDropdown);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="case-search-main-container">
      <div className="case-search-nav">
        <button
          className="back-button"
          style={{ display: "flex", alignItems: "center" }}
          onClick={handleBack}
        >
          <ArrowBackIcon style={{ fontSize: "20px", marginRight: "5px" }} />
          Back
        </button>
        <img
          src={assets.user_icon}
          alt="profile-img"
          onClick={toggleDropdown}
          className="case-search-nav-img"
        />
      </div>
      <div ref={navRef}>
        <ProfileIconDropDown showProfileIconDropdown={showPIDropdown} />
      </div>
      <div className="case-search-secondary-container">
        <p className="case-search-heading1">Upgrade to Premium</p>
        <p className="case-search-heading2">Get more features easily!*</p>
        <p className="case-search-heading">Coming Soon...</p>
      </div>
    </div>
  );
};

export default UpgradePlan;
