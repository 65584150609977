import React, {useState} from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import lawCratsIconBlack from "../assets/LCLogoBlack.png";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel } from '@mui/material';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import ManagePreferenceModal from './ManagePreferenceModal';

const useStyles = makeStyles({
  dialog: {
    backdropFilter: 'blur(5px)',
  },
  dialogPaper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    minWidth: '300px',
    minHeight: '200px',
    '@media (max-width: 600px)': {
      minWidth: '90%',
      padding: '8px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 2,
  },
  liObj: {
    fontSize: "12pt",
    lineHeight: "1.5",
    overflowWrap: "break-word",
    marginBottom: "10px",
    textAlign: "justify",
    textJustify: "inter-word",
    fontFamily: "Times New Roman",
    '@media (max-width: 600px)': {
      fontSize: '9pt',
    },
  },
  dialogTitle: {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 600px)': { 
      textAlign: 'center',
    },
  },
  dialogTitleText: {
    marginLeft: '10px',
    '@media (max-width: 600px)': {
      marginLeft: '0',
    },
  },
  logo: {
    width: '40px',
    '@media (max-width: 600px)': {
      width: '30px',
    },
  },
});

const ConsentFormPrompt = ({open, handleClose}) => {
  const classes = useStyles();
  const [openMPModal, setOpenMPModal] = useState(false);
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [cookieAcceptance, setCookieAcceptance] = useState([true, true, true, true]);

  const handleAcceptCookies = () => {
    const key1 = "lcCCFst"      // lawcrats cookies consent status
    const value1 = "true"

    const key2 = "lcMKTC"       // lawcrats marketing cookies
    const key3 = "lcALTC"       // lawcrats analytical cookies
    const key4 = "lcFNLC"       // lawcrats functional cookies

    localStorage.setItem(key1, value1);
    localStorage.setItem(key2, cookieAcceptance[1].toString());
    localStorage.setItem(key3, cookieAcceptance[2].toString());
    localStorage.setItem(key4, cookieAcceptance[3].toString());

    handleClose();

  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handleMangePreference = () => {
    setOpenMPModal(!openMPModal);
  };

  const handleOpenPrivacyPolicyModal = () => {
    setOpenPrivacyPolicyModal(!openPrivacyPolicyModal);
  };

  return (
    <Dialog
      open={open}
      onClose={null}
      className={classes.dialog}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle style={{ width: "100%" }}>
        <div className={classes.dialogTitle}>
          <img src={lawCratsIconBlack} alt="" className={classes.logo} style={{width: '25px'}}/>
          <h4 className={classes.dialogTitleText}>Your Privacy Matters</h4>
          <IconButton className={classes.closeButton} disabled='true' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <p className={classes.liObj}>
        We use cookies to enhance your browsing experience. You can modify your settings at any time, 
        but please be aware that restricting certain cookies could affect the functionality of the 
        site and the quality of our services.
        </p>
        <div>
          <FormControlLabel
            control={
              <Checkbox checked={isCheckboxChecked} onChange={handleCheckboxChange} />
            }
            label={
              <>
                I understand and accept the use of cookies as outlined in the
                <span
                  onClick={handleOpenPrivacyPolicyModal}
                  style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: '4px' }}
                >
                  Privacy Policy
                </span>.
              </>
            }
            sx={{
              color: 'gray',
              fontSize: '12pt',
              marginLeft: 0,
              gap: '8px',
              '@media (max-width: 600px)': {
                fontSize: '9pt',
              },
            }}
          />
        </div>
        <PrivacyPolicyModal open={openPrivacyPolicyModal} handleClose={handleOpenPrivacyPolicyModal} />
        <ManagePreferenceModal open={openMPModal} handleClose={handleMangePreference} cookieAcceptance={cookieAcceptance} setCookieAcceptance={setCookieAcceptance} />
      </DialogContent>
      <DialogActions style={{ width: "100%" }}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '40px'}}>
          <Button 
            variant='contained' 
            onClick={handleAcceptCookies}
            disabled={!isCheckboxChecked}
            sx={{
              textTransform: 'none',
              backgroundColor: '#6a6c6d',
              '@media (max-width: 600px)': {fontSize: '12px', padding: '6px 12px',},
            }}
          >Accept & Continue</Button>
          <Button onClick={handleMangePreference} sx={{textTransform: 'none', outline: '1px solid #6a6c6d', '@media (max-width: 600px)': {fontSize: '12px', padding: '6px 12px',},}}>Manage Preferences</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ConsentFormPrompt