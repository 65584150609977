import React, { useEffect, useState, useRef } from "react";
import FormattedContent from "../../CommonFiles/FormattedContent.jsx";
import ProfileIconDropDown from "../../CommonFiles/ProfileIconDropdown.jsx";
import "./CaseSearch.css";
import { assets } from "../../assets/assets.js";
import axios from "axios";
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MicNoneIcon from '@mui/icons-material/MicNone';
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";
import { message, notification } from "antd";
import Skeleton from '@mui/material/Skeleton'; 
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";

const CaseSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { caseData } = location.state || {};
  const [summaryData, setSummaryData] = useState(caseData?.summary || "");
  const [showPIDropdown, setShowPIDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryResultArray, setSearchQueryResultArray] = useState([]);
  const [searchIndex, setSearchIndex] = useState("");
  const [isSearchSummaryCalled, setIsSearchSummaryCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [summariserData, setSummarizerData] = useState(null);

  const token = localStorage.getItem('lawCratsToken');
  const navRef = useRef(null)

  useEffect(() => {
    if (!summaryData && caseData?.pdf_link) {
      setLoading(true);
      fetchCaseSummary(caseData.pdf_link).finally(() => setLoading(false));
    }
  }, [caseData, summaryData]);

  const fetchCaseSummary = async (pdfLink) => {
    try {
      const response = await new Promise((resolve) => {
        setTimeout(() => resolve({ data: caseData?.summary || "Generated Summary Data" }), 1000);
      });
      setSummaryData(response.data);
      setIsSearchSummaryCalled(true);
    } catch (error) {
      message.error("Failed to load summary. Please try again.");
    }
  };

  const openNotification = (placement) => {
    api.info({
      message: `Mic has been started!`,
      description:
        "Mic started. Speak now. It will stop automatically, handle carefully.",
      placement,
    });
  }

  const handleQuerySearchInput = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAnotherCaseSearch = () => {
    setIsSearchSummaryCalled(false);
    setSearchQueryResultArray([]);
    setSummaryData("");
  };



  const handleGetQueryResultArray = async () => {
    if (!token) {
      return message.error("Please login first.");
    }
    if (searchQuery === '') {
      return message.error("Please enter the Case name.");
    }
    setLoading(true); // Set loading to true when making the API request
    try {
      const response = await axios.post(
        'https://d3gcxrg9olqhec.cloudfront.net/legal-solutions/case-search-query/',
        { search_query: searchQuery },
        {
          headers: {  
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.message === `No results found for '${searchQuery}'`) {
        return message.error(`No results found for '${searchQuery}'`);
      }
      if (response.status === 200) {
        const extractedData = response.data;
        setSearchQueryResultArray(extractedData);
      }
    }
    catch (error) {
      if (error?.response?.data?.message === "Your credit limit has been exceeded.") {
        message.error("Your credit limit has been exceeded, Upgrade Now!");
      }
      else if(error?.response?.data?.message === "You must verify your email first."){
        message.error("You must verify your email first.");
      } else {
        message.error("Something went wrong. Try Again!");
      }
    } finally {
      setLoading(false); 
    }
  };

  const handleGetSearchSummaryByIndex = async (e) => {
    setIsSearchSummaryCalled(true);
    setSearchIndex(e);
    setSummaryData("");
    setLoading(true); // Set loading to true when making the API request
    if (!token) {
      return message.error("Please login first.");
    }
    try {
      const token = localStorage.getItem('lawCratsToken');
      const response = await axios.post('https://d3gcxrg9olqhec.cloudfront.net/legal-solutions/case-search-summary/',
        { index: e,
          search_query: searchQuery
         },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.status === 200) {
        const extractedData = response.data;
        setSummaryData(extractedData);
      }
    } catch (error) {
      if (error?.response?.data?.message === "Your credit limit has been exceeded.") {
        message.error("Your credit limit has been exceeded, Upgrade Now!");
      }
      else if(error?.response?.data?.message === "You must verify your email first."){
        message.error("You must verify your email first.");
      } else {
        message.error("Something went wrong. Try Again!");
      }
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navRef.current && !navRef.current.contains(event.target) && !event.target.matches(".case-search-nav-img")) {
        if (showPIDropdown) {
          setShowPIDropdown(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPIDropdown]);

  const toggleDropdown = () => {
    setShowPIDropdown(!showPIDropdown);
  };

  const handleBack = () => {
    navigate("/");
  };

  // Function to handle speech recognition
  const startSpeechRecognition = () => {
    openNotification('top');
    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchQuery((prevInput) => prevInput ? `${prevInput} ${transcript}` : transcript); // Append spoken text with a space
    };

    recognition.onerror = (event) => {
      console.error('Error occurred in speech recognition: ', event.error);
    };

    recognition.start();
  };

  return (
    <div className="case-search-main-container">
      {contextHolder}
      <div className="case-search-nav">
        <button className="back-button" style={{ display: 'flex', alignItems: 'center' }} onClick={handleBack}>
          <ArrowBackIcon style={{ fontSize: '20px', marginRight: '5px' }} />Back
        </button>
        <img
          src={assets.user_icon}
          alt="profile-img"
          onClick={toggleDropdown}
          className="case-search-nav-img"
        />
      </div>
      <div ref={navRef}>
        <ProfileIconDropDown showProfileIconDropdown={showPIDropdown} />
      </div>
      <div className="case-search-secondary-container">
        <p className="case-search-heading1">LawCrats Legal Explorer</p>
        {!isSearchSummaryCalled && (
          <p className="case-search-heading2">Get Details In A Blink!*</p>
        )}
        {!isSearchSummaryCalled && (
          <div className="case-search-box">
            <input
              name="input"
              onChange={handleQuerySearchInput}
              value={searchQuery}
              type="text"
              placeholder="Enter Case Name"
            />
            <div style={{ display: 'flex', gap: '15px', justifyContent: 'center', alignItems: 'center' }}>
              <IconButton onClick={startSpeechRecognition} sx={{ width: "0px", height: "35px" }}>
                <MicNoneIcon />
              </IconButton>
              <IconButton onClick={handleGetQueryResultArray} sx={{ width: "0px", height: "35px" }}>
                <SendOutlinedIcon />
              </IconButton>
            </div>
          </div>
        )}
        {!isSearchSummaryCalled && (
          <div className="case-search-small-card">
            {loading ? (
              <>
                <Skeleton variant="rounded" width="100%" height={50} animation="wave" style={{ marginBottom: 10 }} />
                <Skeleton variant="rounded" width="100%" height={50} animation="wave" style={{ marginBottom: 10 }} />
                <Skeleton variant="rounded" width="100%" height={50} animation="wave" style={{ marginBottom: 10 }} />
              </>
            ) : (
              Array.isArray(searchQueryResultArray) && searchQueryResultArray.map((item, index) => {
                if (index !== undefined && index !== null) {
                  return (
                    <div className="smallCard" key={index} onClick={() => handleGetSearchSummaryByIndex(item.index)}>
                      <p
                        style={{
                          fontSize: "18px",
                          minWidth: "200px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          backgroundColor: "#c4c7c5",
                          borderRadius: "4px",
                          padding: "3px 0",
                          textAlign: 'center',
                          fontWeight: '600',
                        }}>{item?.case_title}</p>
                      <p style={{ fontSize: "13px", padding: "3px 0" }}><span style={{ fontWeight: '600' }}>Case No.-</span> {item?.case_no}</p>
                      <p style={{ fontSize: "13px", padding: "3px 0" }}><span style={{ fontWeight: '600' }}>Judges -</span>{item?.judges}</p>
                      <p style={{ fontSize: "13px", padding: "3px 0" }}><span style={{ fontWeight: '600' }}>Disposal nature -</span>{item?.disposal_nature}</p>
                      <span className="onHoverSpan" onClick={() => window.open(item.pdf_link, '_blank')} style={{ display: "flex", flexDirection: "row", fontSize: "13px", alignItems: "center", gap: "15px", width: "100%" }}><AttachmentOutlinedIcon /> Read the Document</span>
                    </div>
                  );
                }
                return null;
              })
            )}
          </div>
        )}
        {isSearchSummaryCalled && (
          <>
            {loading ? (
              <Box sx={{ width: "80%" }}>
                <Skeleton height={60} sx={{ marginBottom: 2 }} />
                <Skeleton animation="wave" height={60} sx={{ marginBottom: 2 }} />
                <Skeleton  height={60} sx={{ marginBottom: 2 }}/>
                <Skeleton animation="wave" height={60} sx={{ marginBottom: 2 }} />
                <Skeleton height={60} sx={{ marginBottom: 2 }} />
                <Skeleton animation="wave" height={60} sx={{ marginBottom: 2 }} />
              </Box>
            ) : (
              <div className="generated-summary-container">
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", backgroundColor: "#f0f4f9", padding: "25px", borderRadius: "10px" }}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}><span>Case Title:</span><span>{summaryData?.case_title ||caseData?.case_title}</span></div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}><span>Case Number:</span><span>{summaryData?.case_no || caseData?.case_no}</span></div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}><span>Judges:</span><span>{summaryData?.judges || caseData?.judges}</span></div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}><span>Disposal Nature:</span><span>{summaryData?.disposal_nature || caseData?.disposal_nature}</span></div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}><span>Decision Date:</span><span>{summaryData?.decision_date || caseData?.decision_date}</span></div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "30px", cursor: 'pointer', alignItems: 'center' }}>
                    <span>Read Full Case Document:</span>
                    <span onClick={() => window.open(summaryData?.pdf_link || caseData?.pdf_link, '_blank')} style={{ display: "flex", alignItems: "center", color: "#d32f2f" }}>
                      <PictureAsPdfTwoToneIcon /> Click here
                    </span>
                  </div>
                  <div className="centeredHeaderLineBothSideCS">
                    <hr className="hrLeftCS" />
                      <span>Summary</span>
                    <hr className="hrRightCS"/>
                  </div>
                  <FormattedContent text={summaryData?.summary || caseData?.summary} />
                </div>
                {/* <button onClick={handleAnotherCaseSearch} className="back-button" style={{ marginTop: "20px" }}>
                  Search Another Case
                </button> */}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CaseSearch;
