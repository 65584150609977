import React from "react";
import vs from "../../../assets/LawCratsVideo.mp4";
import "./HomeAbout.css";

const HomeAbout = ({ isDarkMode }) => {
  const iconColor = isDarkMode ? "#FFFFFF" : "#FFFFFF";
  
  return (
    <div className={`${isDarkMode ? "dark-mode" : ""}`}>
      <div className={`card-container-ha ${isDarkMode ? "dark-mode" : ""}`}>
        <div className="video-section">
          <video
            src={vs}
            width="600"
            height="400"
            controls
            autoPlay
            loop
            muted
            playsInline
            controlsList="nodownload noremoteplayback"
            disablePictureInPicture={false}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>

  );
};

export default HomeAbout;
