import { message } from 'antd';
import axios from 'axios';




/* the backend response json has these keys: 
question: the prompt from frontend
chat_history: [] basically an array to store chat history
answer: the generated answer based on the question */

async function run(prompt) {
  try {
    const token = localStorage.getItem('lawCratsToken');
    const response = await axios.post('https://d3gcxrg9olqhec.cloudfront.net/legal-solutions/lawchatbot/',
      { query: prompt },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    const answer = response.data.answer_text;
    return answer;
  } catch (error) {
    if (error?.response?.data?.message === "Your credit limit has been exceeded.") {
      message.error("Your credit limit has been exceeded, Upgrade Now!");
    }
    else if(error?.response?.data?.message === "You must verify your email first."){
      message.error("You must verify your email first.");
    } else {
      message.error("Something went wrong. Try Again!");
    }
  }
}

export default run;