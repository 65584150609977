import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // For redirecting
import { IconButton } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MicNoneIcon from '@mui/icons-material/MicNone';
import "./HomeTestimonial.css";
import { notification } from "antd";

// Check if SpeechRecognition API is available in the browser
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = SpeechRecognition ? new SpeechRecognition() : null;

const HomeTestimonial = ({ isDarkMode }) => {
  const [inputValue, setInputValue] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const token = localStorage.getItem("lawCratsToken");

  useEffect(() => {
    if (recognition) {
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = "en-US";

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setInputValue((prevValue) => prevValue + transcript);
        setIsRecording(false);
      };

      recognition.onerror = () => {
        setIsRecording(false);
      };
    }
  }, []);

  const openNotification = (placement) => {
    api.info({
      message: `Mic has been started!`,
      description: "Mic started. Speak now. It will stop automatically, handle carefully.",
      placement,
    });
  }

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleSend = () => {
    if (inputValue.trim() !== "") {
      // Redirect to Main with inputValue as state
      navigate("/law-chat-bot", { state: { input: inputValue } });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      handleSend();
    }
  };

  const handleMicClick = () => {
    openNotification('top');
    if (recognition) {
      if (isRecording) {
        recognition.stop();
        setIsRecording(false);
      } else {
        recognition.start();
        setIsRecording(true);
      }
    }
  };

  return (
    <div className={`home-testimonial ${isDarkMode ? "dark-mode" : ""}`}>
      {contextHolder}
      <div className="recent-case-studies">
        <h2>Ask to LawCrats Legal Assistant</h2>
      </div>
      <div className="ask-for-any-case">
        <div className="input-container">
          <input
            name="input"
            type="text"
            placeholder="Ask to LawCrats Legal Assistant..."
            value={inputValue}
            onInput={handleInput}
            onKeyPress={handleKeyPress}
          />
          {token &&
            <div className="input-icons" style={{ display: 'flex', gap: '15px', justifyContent: 'center', alignItems: 'center' }}>
              <IconButton onClick={handleMicClick} sx={{ width: "0px", height: "35px" }}>
                <MicNoneIcon style={{ color: isDarkMode ? "white" : "black" }} />
              </IconButton>
              <IconButton onClick={handleSend} sx={{ width: "0px", height: "35px" }}>
                <SendOutlinedIcon style={{ color: isDarkMode ? "white" : "black" }} />
              </IconButton>
            </div>
          }
          {
            !token &&
            <div className="input-icons">
              <button className="login" style={{backgroundColor: isDarkMode ? "white" : "black" }}>
                <Link to="/auth-user" style={{ color: isDarkMode ? "black" : "white"}}>
                  <span>Login</span>
                </Link>
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default HomeTestimonial;
