import React from 'react'
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import NewMultiStepFormHandler from './NewMultiStepFormHandler';

const useStyles = makeStyles(()=>({
    blueBgmFullWdth: {
        width: '100%',
        display: 'flex',
        flexDirection: "column",
        background: 'white',
    },
    
    commonNavbarDrafting:{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "22px",
        paddingTop: "10px",
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingBottom: "10px",
    },
    
    logo: {
        width: '40px',
        '@media (max-width: 600px)': { 
            width: '30px', 
        },
    },

    twoRectBoxContainer: {
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflowY: 'auto',
        '@media (max-width: 1000px)': {
            flexDirection: 'column',
            overflowY: 'scroll',
            gap: '20px',
            marginBottom: '50px',
        },
    },
    leftSideBox: {
        flex: 1,
        width: '55vw',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        '@media (max-width: 1000px)': {
            width: '100%',
        },
    },
    stepper: {
        marginTop: '5px',
        marginBottom: "15px",
        width: '53vw',
        '@media (max-width: 1000px)': {
            width: '100%',
        },
    },
    userInputBox: {
        width: '100%',
        overflowY: 'auto',
        maxHeight: '72vh',
        flex: 1,
        '@media (max-width: 1000px)': {
            width: '100%',
            maxHeight: 'none',
        },
    },
    rightSideBox: {
        flex: 1,
        width: '42vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: '#eaeaea',
        boxSizing: 'border-box',
        scrollbarWidth: 'none',
        position: 'relative',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-width: 1000px)': {
            width: '100%',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            padding: "12px 0",
            alignItems: 'center',
            overflow: 'hidden',
            backgroundColor: '#eaeaea',
        },
    },
    
    iframe: {
        width: '90%',
        height: '90%',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: 'white',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-width: 1000px)': {
            width: '95%',
            height: "500px",
            padding: "15px",
        },
        '@media (max-width: 400px)': {
            width: '95%',
            height: "200px",
            padding: "15px",
        },
        '@media (max-width: 600px)': {
            width: '95%',
            height: "350px",
            padding: "15px",
        },
        '@media (max-width: 800px)': {
            width: '95%',
            height: "400px",
            padding: "15px",
        },
    },
    
    previewButtonPre: {
        position: 'absolute',
        bottom: '10px', // Adjust for desired padding from bottom
        left: '10px',   // Adjust for desired padding from left
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        gap: "3px",
        zIndex: 10, // Ensures button appears above iframe
    },
    
    
    /* Bellow sections are for case step === 5 i.e additional details; the overall layout would be different from previous layout*/
    spclTwoRectBoxCont: {
        marginTop: '15px',
        backgroundColor: '#fffff',
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0 15px',
        '@media (max-width: 1000px)': {
            height: '180vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    additionalDataInputBox: {
        width: '65vw', // Adjust the width to complement previewAndBillingBox
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        maxHeight: '85vh',
        overflowY: 'auto',
        position: 'relative',
    },
    
    previewAndBillingBox: {
        width: '30vw', // Ensures it takes up the remaining space
        height: '85vh',
        border: '1px solid blue',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        padding: '20px',
        //backgroundColor: 'yellow',
        display: 'flex',
        flexDirection: 'column',
    },
    rentPreviewBox: {
        position: 'relative', // Ensure this box is relative so the button can be absolutely positioned within it
        overflow: 'hidden',
        borderRadius: '8px',
        border: '1px solid black',
        backgroundColor: '#f1f1f1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 0,
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: 0.5,
        },
    },
    previewButton: {
        opacity: 1,
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        backgroundColor: 'red',
        transition: 'opacity 0.3s ease',
    },
    billingDetails: {
        fontSize: '14px',
        fontFamily: 'Open Sans',
        fontColor: '#7F7F7F',
        fontWeight: '600',
    },
    nav: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        zIndex: 2,
      },
      profileImg: {
        cursor: 'pointer',
        width: '50px', 
        height: '50px', 
        borderRadius: '50%', 
        objectFit: 'cover', 
      },
}));

const NewDraftPageBody = () => {
  const classes = useStyles();
  const [currentStepData, setCurrentStepData] = useState(1);

    const handleStepData = (data) => {
        setCurrentStepData(data);
    };
    
    const steps = [
        { label: 'Landlord Details', position: 1 },
        { label: 'Tenant Details', position: 2 },
        { label: 'Property Details', position: 3 },
        { label: 'Agreement Details', position: 4 },
        { label: 'Additional', position: 5}
    ];
  return (
    <div>
        <NewMultiStepFormHandler classes={classes} currentStep={currentStepData} onStepDataChange={handleStepData}/>
    </div>
  )
}

export default NewDraftPageBody;