import React, { useState } from 'react'
import HomeNavbar from '../components/CommonHomePage/HomeNavbar/HomeNavbar';
import HomeFooter from '../components/CommonHomePage/HomeFooter/HomeFooter';
import StickyFooter from '../components/CommonHomePage/StickyFooter/StickyFooter';


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    ppBody: {
        marginTop: "60px",
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '300px',
        minHeight: '200px',
        '@media (max-width: 600px)': {
            minWidth: '90%',
            padding: '8px',
        },
    },
    ppContainer: {
        padding: "10px 45px",
        '@media (max-width: 600px)': {
            padding: '10px',
        },
    },
    liObj: {
        fontSize: "12pt",
        lineHeight: "1.5",
        overflowWrap: "break-word",
        marginBottom: "10px",
        textAlign: "justify",
        textJustify: "inter-word",
        fontFamily: "Times New Roman",
        '@media (max-width: 600px)': {
            fontSize: "9pt",
        },
    },
    
});

const PrivacyPolicyPage = () => {
    const classes = useStyles();
    const [isDarkMode, setIsDarkMode] = useState(true);

    const handleToggleDarkMode = (darkMode) => {
        setIsDarkMode(darkMode);
    };
    return (
        <div className={`common-homepage ${isDarkMode ? "dark" : ""}`}>
            <HomeNavbar onToggleDarkMode={handleToggleDarkMode} />
            <div className={classes.ppBody} style={{backgroundColor: isDarkMode? "black" : "white", color: isDarkMode? "white" : "black", paddingBottom: "40px"}}>
                <div style={{fontSize: "36px", fontWeight: "500", fontFamily: "Montserrat, sans-serif", paddingTop: "50px", paddingBottom: "20px"}}>Privacy Policy</div>
                <div className={classes.ppContainer}>
                    <ul>
                        <li className={classes.liObj}>
                            <h2>Introduction</h2>
                            <p className={classes.pContent}>
                            Welcome to LawCrats's website! We value your privacy and are committed to protecting your personal information. 
                            This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website. Our goal is to provide a transparent overview 
                            of our practices to ensure your trust. Please take a moment to read through our policies carefully.
                            </p>
                        </li>
                        
                        <li className={classes.liObj}>
                            <h2>Information We Collect</h2>
                            <p className={classes.pContent}>
                            <strong>Personal Information:</strong> We may collect the following types of personal information: 
                            <br/>
                            <strong>Contact Information:</strong> When you fill out our contact form or subscribe to our newsletter, we collect your name, email address, phone number, 
                            and other relevant details. This information helps us maintain communication and offer personalized services. 
                            <br/>
                            <strong>Usage Data:</strong> We automatically collect information about your interaction with our website, including IP addresses, browser type, 
                            pages visited, and referral sources. This helps us understand user behavior and improve our services.
                            </p>
                        </li>
                        
                        <li className={classes.liObj}>
                            <h2>Cookies</h2>
                            <p className={classes.pContent}>
                            We use cookies to enhance your browsing experience. Cookies are small text files stored on your device that help us analyze website traffic and improve 
                            our services. You can adjust your browser settings to manage cookies or opt-out if you prefer. We primarily use four types of cookies:
                            <br/>
                            <strong>Technical Cookies:</strong> Essential for the proper functioning of our website, enabling core functionalities such as page navigation, 
                            security, and session management. These cookies allow you to navigate the site smoothly and utilize its features.
                            <br/>
                            <strong>Marketing Cookies:</strong> Help us deliver personalized ads and promotional content based on your interests. Disabling marketing cookies may 
                            result in less relevant advertising.
                            <br/>
                            <strong>Analytical Cookies:</strong> Collect anonymous data to analyze website traffic, identify trends, and enhance user experience. These cookies 
                            enable data-driven improvements.
                            <br/>
                            <strong>Functional Cookies:</strong> Enhance your experience by enabling personalized features such as language preferences and login sessions. 
                            Disabling them may impact certain website functionalities.
                            </p>
                        </li>
                        
                        <li className={classes.liObj}>
                            <h2>How We Use Your Information</h2>
                            <p className={classes.pContent}>
                            <strong>Communication:</strong> We use your contact information to respond to your inquiries, provide legal updates, and send newsletters (if subscribed). 
                            Our aim is to keep you informed and updated on relevant information and services. 
                            <br/>
                            <strong>Analytics:</strong> We analyze usage data to improve our website’s functionality, content, and user experience. These insights allow us to 
                            optimize your browsing experience and ensure you receive the most relevant content.
                            </p>
                        </li>
                        
                        <li className={classes.liObj}>
                            <h2>Data Security</h2>
                            <p className={classes.pContent}>
                            We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no 
                            method of transmission over the internet is entirely secure, so we cannot guarantee absolute security. We continuously work to enhance our security 
                            practices and maintain a secure environment for your data.
                            </p>
                        </li>
                        
                        <li className={classes.liObj}>
                            <h2>Third-Party Services</h2>
                            <p className={classes.pContent}>
                            Our website may contain links to third-party websites or services. Please review their privacy policies as we are not responsible for their practices. 
                            These external sites operate independently, and we encourage you to read their policies carefully. Our aim is to work with trusted third-party 
                            partners to maintain your data privacy.
                            </p>
                        </li>
                        
                        <li className={classes.liObj}>
                            <h2>Your Rights</h2>
                            <p className={classes.pContent}>
                            You have the right to:
                            <br/>
                            - Access, update, or delete your personal information.<br/>
                            - Opt-out of receiving marketing communications.<br/>
                            - Request a copy of the data we hold about you.<br/>
                            We respect your privacy rights and are dedicated to providing you with control over your personal information. 
                            If you have questions about your rights, please contact us.
                            </p>
                        </li>
                        
                        <li className={classes.liObj}>
                            <h2>Consent</h2>
                            <p className={classes.pContent}>
                            By using our website and clicking the “Accept” button, you agree to our Privacy Policy and Legal Services Disclosure, consenting to the collection 
                            and use of your information as described herein. Your continued use of the website serves as an acknowledgment of your acceptance and compliance with 
                            our policies.
                            </p>
                        </li>
                        
                        <li className={classes.liObj}>
                            <h2>Changes to this Policy</h2>
                            <p className={classes.pContent}>
                            We may update this Privacy Policy periodically. Any changes will be posted on this page. By continuing to use our website, you agree to these 
                            updates, so we encourage you to review this policy regularly to stay informed.
                            </p>
                        </li>
                        
                        <li className={classes.liObj}>
                            <h2>Contact Us</h2>
                            <p className={classes.pContent}>
                            If you have any questions or concerns about our Privacy Policy, please contact us at <strong>support@lawcrats.com</strong>. 
                            We value your feedback and are here to address any questions or concerns you may have regarding our privacy practices.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <HomeFooter isDarkMode={isDarkMode} />
            <StickyFooter isDarkMode={isDarkMode} />
        </div>
    )
}

export default PrivacyPolicyPage