import React, { useState } from 'react'
import HomeNavbar from '../components/CommonHomePage/HomeNavbar/HomeNavbar';
import HomeFooter from '../components/CommonHomePage/HomeFooter/HomeFooter';
import StickyFooter from '../components/CommonHomePage/StickyFooter/StickyFooter';


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    ppBody: {
        marginTop: "60px",
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '300px',
        minHeight: '200px',
        '@media (max-width: 600px)': {
            minWidth: '90%',
            padding: '8px',
        },
    },
    ppContainer: {
        padding: "10px 45px",
        '@media (max-width: 600px)': {
            padding: '10px',
        },
    },
    liObj: {
        fontSize: "12pt",
        lineHeight: "1.5",
        overflowWrap: "break-word",
        marginBottom: "10px",
        textAlign: "justify",
        textJustify: "inter-word",
        fontFamily: "Times New Roman",
        '@media (max-width: 600px)': {
            fontSize: "9pt",
        },
    },
    
});

const TermsOfServicePage = () => {
    const classes = useStyles();
    const [isDarkMode, setIsDarkMode] = useState(true);

    const handleToggleDarkMode = (darkMode) => {
        setIsDarkMode(darkMode);
    };
    return (
        <div className={`common-homepage ${isDarkMode ? "dark" : ""}`}>
            <HomeNavbar onToggleDarkMode={handleToggleDarkMode} />
            <div className={classes.ppBody} style={{backgroundColor: isDarkMode? "black" : "white", color: isDarkMode? "white" : "black", paddingBottom: "40px"}}>
                <div style={{fontSize: "36px", fontWeight: "500", fontFamily: "Montserrat, sans-serif", paddingTop: "50px", paddingBottom: "20px"}}>Terms Of Services</div>
                <div className={classes.ppContainer}>
                    <ul>
                        <li className={classes.liObj}>
                            <h2>Introduction</h2>
                            <p className={classes.pContent}>Welcome to LawCrats's website! By accessing and using our website, you agree to comply with these Terms of Service. Please read them carefully 
                                to understand your rights and obligations while using our services. These terms constitute a legally binding agreement between you and LawCrats, so it is essential to 
                                review them thoroughly before continuing to use the website.
                            </p>
                        </li>
                        <li className={classes.liObj}>
                            <h2>Acceptance of Terms</h2>
                            <p className={classes.pContent}>By using our website, you acknowledge that you have read, understood, and agreed to these terms. Your continued use of our website signifies
                                your acceptance of these terms and conditions. If you do not agree to any part of these terms, please refrain from using our services and discontinue accessing our site 
                                immediately. We reserve the right to update or modify these terms at any time, so please check back periodically for any changes.
                            </p>
                        </li>
                        <li className={classes.liObj}>
                            <h2>Services Offered</h2>
                            <h3>Copyright:</h3><p className={classes.pContent}>All content on our website, including text, images, videos, and graphics, is protected by copyright laws. You may not reproduce, 
                                distribute, or modify any content without our permission. Unauthorized use of our content is strictly prohibited and may result in legal action to protect our intellectual 
                                property rights. If you wish to use our content for any purpose other than personal use, please contact us to request permission.
                            </p>
                            <h3>License:</h3><p className={classes.pContent}> We grant you a limited, non-exclusive, revocable license to access and use our website for personal, non-commercial purposes.
                                This license does not permit you to modify or reproduce our content for distribution. Any commercial or unauthorized use of our content will require explicit permission from us. 
                                This license may be revoked at any time if we determine that you have violated these terms.
                            </p>
                        </li>
                        <li className={classes.liObj}>
                            <h2>Disclaimer</h2>
                            <h3>Legal Advice:</h3><p className={classes.pContent}>We provide legal advice, opinions, and guidance based on our expertise. However, our advice does not guarantee specific outcomes, 
                                as legal matters are subject to various factors. Always consult with a qualified attorney for complex legal issues or advice tailored to your unique situation. LawCrats is not 
                                responsible for any actions taken based on the general information available on our website.
                            </p>
                            <h3>Accuracy:</h3><p className={classes.pContent}>While we strive for accuracy, we do not guarantee the completeness or correctness of the content. Use it at your own risk. 
                                We encourage users to verify information and consult other sources if necessary. Our website is updated periodically, but we cannot guarantee that all information will 
                                always be current or error-free.
                            </p>
                        </li>
                        <li className={classes.liObj}>
                            <h2>Privacy Policy</h2>
                            <p className={classes.pContent}>Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our website, you consent to our privacy 
                                practices as described in the Privacy Policy. We take your privacy seriously and implement security measures to safeguard your information, but we cannot guarantee 
                                absolute security. We recommend that you review our Privacy Policy regularly to stay informed about how we protect your data.
                            </p>
                        </li>
                        <li className={classes.liObj}>
                            <h2>Limitation of Liability</h2>
                            <p className={classes.pContent}>We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of our website. This limitation 
                                applies to any harm resulting from inaccuracies, errors, interruptions, or any other issues related to the website. By using our services, you agree to waive any 
                                claims against LawCrats and its affiliates for damages that may arise from accessing or using our website.
                            </p>
                        </li>
                        <li className={classes.liObj}>
                            <h2>Governing Law</h2>
                            <p className={classes.pContent}>These Terms of Service are governed by the laws of India. Any disputes will be subject to the exclusive jurisdiction of the courts in Ghaziabad, 
                                Uttar Pradesh. By agreeing to these terms, you consent to this jurisdiction and waive any objections to this venue.
                            </p>
                        </li>
                        <li className={classes.liObj}>
                            <h2>Contact Us</h2>
                            <p className={classes.pContent}>If you have any questions or concerns regarding these terms, please contact us at <strong>support@lawcrats.com</strong>. We are committed to providing transparent 
                                and accessible service, and we will make every effort to address your inquiries promptly.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <HomeFooter isDarkMode={isDarkMode} />
            <StickyFooter isDarkMode={isDarkMode} />
        </div>
    )
}

export default TermsOfServicePage