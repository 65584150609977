import React from "react";
import ContextProvider from "./context/Context";
import CaseSearch from "../CaseSearchpage/CaseSearch/CaseSearch"
import Sidebar from "../components/ComponentsCaseSearch/Sidebar/Sidebar";

const CaseSearchPage = () => {
  return (
    <>
      <ContextProvider>
        <div className="case_search_main" style={{ display: "flex" }}>
          <Sidebar />
          <CaseSearch />
        </div>
      </ContextProvider>
    </>
  );
};

export default CaseSearchPage;
