import React, { useEffect, useState } from "react";
import img from "../../../assets/Lawcrtas logo final.png";
import img6 from "../../../assets/white version.png";
import { Link, useNavigate } from "react-router-dom";
import "./HomeNavbar.css";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { message } from "antd";

const HomeNavbar = ({ onToggleDarkMode }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  // State to track active li
  const [clicked, setClicked] = useState(false);
  const token = localStorage.getItem("lawCratsToken");
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNightlightToggle = () => {
    setIsDarkMode((prevMode) => !prevMode);
    onToggleDarkMode(!isDarkMode); 
  };

  const handleCalendlyRedirect = () => {
    window.location.href = "https://outlook.office365.com/book/GetFreeOnlineLegalAdviceToday@lawcrats.com/";
  };

  const handleScrollToSection = (sectionId, index) => {
    setActiveIndex(index);
    navigate("/");
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  const handleLogout = () => {
    localStorage.removeItem("lawCratsToken");
    navigate("/auth-user");
    message.success("Logged out successfully.")
  }

  return (
    <div className={`homenav_bar ${isScrolled ? "scrolled" : ""} ${isDarkMode ? "dark" : "" }`}>
      <div className="homenavbar_left">
        <img src={isDarkMode ? img6 : img} alt="search icon" />
        <Link to={"/"}>
          <h1>
            <span className="law">Law</span>
            <span className="crats">Crats</span>
          </h1>
        </Link>
      </div>

      <div className="homenavbar_mid">
        <ul className={clicked ? "nav-menu active" : "nav-menu"}>
          <li
            className={activeIndex === 0 ? "active" : ""}
            onClick={() => handleScrollToSection("home-section", 0)}
          >
            Home
          </li>
          <li
            className={activeIndex === 1 ? "active" : ""}
            onClick={() => handleScrollToSection("solutions-section", 1)}
          >
            Solutions
          </li>
          <li
            className={activeIndex === 2 ? "active" : ""}
            onClick={() => navigate("/about-us")}
          >
            About Us
          </li>
          {/* <li
            className={activeIndex === 1 ? "active" : ""}
            onClick={() => navigate("/auth-user")}
          >
            Career
          </li> */}
          <li
            className={activeIndex === 3 ? "active" : ""}
            onClick={() => handleScrollToSection("contacts-section", 3)}
          >
            Contacts
          </li>
          

          <button
            className="login hide_button"
            style={{ padding: "5px", color: isDarkMode ? "#000" : "#fff", fontSize: "14px"}}
            onClick={handleCalendlyRedirect}
          >
            Book a Demo
          </button>
          {
            !token &&
            <Link to="/auth-user" style={{ color: isDarkMode ? "#000" : "#fff" }}>
              <button className="login hide_button">Login</button>
            </Link>
          }
          {
            token &&
            <button onClick={handleLogout} className="hide_nav_button_desktop"
              style={{
                color: isDarkMode ? "#000" : "#fff",
                backgroundColor: isDarkMode ? "#fff" : "#000",
              }}>
              Logout
            </button>
          }
        </ul>
      </div>

      <div className="homenavbar_right">
        <button className="nightlight" onClick={handleNightlightToggle}>
          {isDarkMode ? (
            <LightModeOutlinedIcon sx={{ color: "white" }} />
          ) : (
            <DarkModeIcon />
          )}
        </button>
        <button
          className="login hide_nav_button"
          style={{ padding: "5px" }}
          onClick={handleCalendlyRedirect}
        >
          <Link
            style={{ color: isDarkMode ? "#000" : "#fff", fontSize: "14px" }}
          >
            Book a Demo
          </Link>
        </button>
        {!token && <button className="login hide_nav_button">
          <Link to="/auth-user" style={{ color: isDarkMode ? "#000" : "#fff" }}>
            Login
          </Link>
        </button>}
        {token &&
          <button onClick={handleLogout} className="login hide_nav_button" style={{ color: isDarkMode ? "#000" : "#fff" }}>
            <span style={{ color: isDarkMode ? "#000" : "#fff" }}>
              Logout
            </span>
          </button>}
      </div>
      <div className="menu-icons" onClick={() => setClicked((prev) => !prev)}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
    </div>
  );
};

export default HomeNavbar;