import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LawChatBotPage from "./LawChatBot/LawChatBotPage.jsx";
import CaseReminder from "./CaseReminder/CaseReminderPage.jsx";
import NewDraftDataInput from "./RentalAgreementDrafting/NewDraftDataInput.jsx";
import LandingPage from "./LandingPage.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginPage from "./components/LoginPage/LoginPage.jsx";

import CaseSummariserPage from "../src/CaseSummariserPage/CaseSummariserPage.jsx";
import PrivacyPolicyPage from "./CommonFiles/PrivacyPolicyPage.jsx";
import TermsOfServicePage from "./CommonFiles/TermsOfServicePage.jsx";

import HomePage from "./HomePage.js";
import CaseSearchPage from "./CaseSearchpage/CaseSearchPage.jsx";
import LawCraftDraftMasterPage from "./LawCraftDraftMasterPage/LawCraftDraftmaster.jsx";
import UpgradePlan from "./CommonFiles/UpgradePlan.jsx"; 

import { GoogleOAuthProvider } from "@react-oauth/google";

import AboutUs from "../src/components/CommonHomePage/AboutUs/AboutUs.jsx"

const App = () => {
  return (
    <GoogleOAuthProvider clientId="822822311459-pev48ji2980n2ojo7e5glljmkv4ck97e.apps.googleusercontent.com">
      <Router>
        <Routes>
          {/* before pushing the code please update following two lines. */}
          <Route path="/" element={<LandingPage />} />

          <Route path="/new-rad" element={<NewDraftDataInput />} />

          <Route path="/law-chat-bot" element={<LawChatBotPage />} />

          <Route path="/case-reminder" element={<CaseReminder />} />

          <Route path="/auth-user" element={<LoginPage />} />

          <Route path="/casesummariser" element={<CaseSummariserPage />} />

          <Route path="/casesearch" element={<CaseSearchPage />} />

          <Route path="/home-page-login" element={<HomePage />} />

          {/* <Route path="/new-rad" element={<LawCraftDraftMasterPage/>}/> */}

          <Route path="/upgrade-plan" element={<UpgradePlan/>}/>

          <Route path="/about-us" element={<AboutUs/>}/>
            
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;