import React, { useState, useRef, useEffect } from 'react';
import NewDraftPageBody from '../components/CompRentalAgreementDrafting/NewDraftPageBody.jsx';
import { makeStyles } from '@mui/styles';

const NewDraftDataInput = () => {
  return (
    <NewDraftPageBody />
  );
};

export default NewDraftDataInput;
